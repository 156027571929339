/* styles/Global.css */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Georgia, serif;
  background-color: #F5F5F5;
  color: #000000;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
  padding: 0;
}
